<template>
    <div>
        <h1>邮箱认证</h1>
        <p>正在验证您的邮箱，请稍候...</p>
    </div>
</template>
  
<script>
import { mapActions } from "vuex";
export default {
    name: "EmailVerify",
    mounted() {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get("userId");
        const secret = params.get("secret");
        this.semailVerify(userId, secret);
    },
    methods: {
        ...mapActions(["emailVerify"]),
        async semailVerify(userId, secret) {
            await this.emailVerify({ userId: userId, secret: secret });
            this.$router.push('/');
        }
    }
};
</script>