<template>
    <section class="container h-screen mx-auto flex">
        <div class="flex-grow flex flex-col max-w-xl justify-center p-6">
            <h1 class="text-6xl font-bold">删除账户</h1>
            <form @submit="handleDeleteAccount">
                <h2 class="text-4xl mt-6">感谢使用 Wishing</h2>
                <div class="mt-6">
                    <button type="submit" class="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md bg-gray-900 text-white border hover:border-gray-900 hover:text-gray-900 hover:bg-white focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed">
                        确认删除
                    </button>

                    <button type="button" @click="$router.back()" class="mx-auto ml-4 mt-4 py-4 px-16 font-semibold rounded-lg shadow-md  border hover:border-gray-900 hover:text-gray-900 hover:bg-white focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed">
                    返回
                </button>
                </div>
            </form>
        </div>
    </section>
</template>
    
    
<script>
import { setTransitionHooks } from "vue";
import { mapActions } from "vuex";
export default {
    name: "DeleteAccount",
    data() {
        return {
            
        };
    },
    mounted() {
        
    },
    methods: {
        ...mapActions(["deleteAccount", "logout"]),
        async handleDeleteAccount(e) {
            e.preventDefault();
            try {
                await this.deleteAccount();
                this.$toast.success("删除成功", { position: 'bottom' });
                this.$router.replace("/");
            } catch (e) {
                this.$toast.error(e, { position: 'bottom' });
            }
        },
    }
};
</script>