<template>
    <section class="container h-screen mx-auto flex">
        <div class="flex-grow flex flex-col max-w-xl justify-center p-6">
            <h1 class="text-6xl font-bold">Forget password</h1>
            <form @submit="applyResetPwd">
                <label class="block mt-6"> Email </label>
                <input v-model="email" class="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900" type="text" />

                <div class="mt-6">
                    <button :disabled="!email" type="submit" class="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md bg-gray-900 text-white border hover:border-gray-900 hover:text-gray-900 hover:bg-white focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed">
                        重置密码
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>
    
    
<script>
import { mapActions } from "vuex";
export default {
    name: "ApplyResetPwd",
    data() {
        return {
            email: "",
        };
    },
    methods: {
        ...mapActions(["createRecovery"]),
        applyResetPwd(e) {
            e.preventDefault();
            try {
                this.createRecovery({
                    email: this.email
                });
                this.$toast.success("邮件已发送到您的邮箱，请前往邮箱确认", { position: 'bottom' });
                this.email = null;
            } catch (e) {
                this.$toast.error(e);
            }
        },
    }
};
</script>